import React from "react"

export default props => (
    <div className='videoWrapper'>
        <iframe 
            src="https://www.youtube-nocookie.com/embed/vvtkDEFg3nA"
            title="trailer"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
        </iframe>
    </div>
)
