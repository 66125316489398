import React from "react"
import appStoreLinksStyles from "./app-store-links.module.scss"
import { StaticQuery, graphql } from "gatsby"

import microsoftBadge from '../images/windows-store.svg'
import googlePlayBadge from '../images/google-play-badge.svg'

export default props => (
    <StaticQuery
    query={graphql`
      query {
        windowsStoreIcon: file(relativePath: { eq: "windows-store-btn.png" }) {
            ...appIconImage
        },
        playStoreIcon: file(relativePath: { eq: "play-store-btn.png" }) {
            ...appIconImage
        },
      }
    `}
    render={data => (
        <ul className={appStoreLinksStyles.appStoreList}>
            {props.windowsURL && (
                <li className={appStoreLinksStyles.appStoreListItem}>
                    <a href={props.windowsURL} target="_blank" alt="" rel="noopener noreferrer">
                        <img src={microsoftBadge} alt="" />
                    </a>
                </li>
            )}
            {props.androidUrl && (
                <li className={appStoreLinksStyles.appStoreListItem}>
                    <a href={props.androidUrl} target="_blank" alt="" rel="noopener noreferrer">
                        <img src={googlePlayBadge} alt="" />
                    </a>
                </li>
            )}
        </ul>
    )}
  />
)